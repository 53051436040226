:root {
    --primary: #e30512;
    --gray-50: rgb(249 250 251);
    --gray-100: rgb(243 244 246);
    --gray-200: rgb(229 231 235);
    --gray-300: rgb(209 213 219);
    --gray-400: rgb(156 163 175);
    --gray-500: rgb(107 114 128);
    --gray-600: rgb(75 85 99);
    --gray-700: rgb(55 65 81);
    --gray-800: rgb(31 41 55);
    --gray-900: rgb(17 24 39);
}

body {
    padding-block: 3rem;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}