header {
    background: var(--primary);
    margin-inline: -50px;
}

main {
    margin-top: 5rem;
}

button {
    cursor: pointer;
}

/* Font */
h1, h2, h3, p {
    color: var(--gray-800);
}

p {
    font-size: 1rem;
    margin-block: 1.5rem;
}

h1 {
    font-size: 2rem;
}

a {
    color: var(--primary);
    text-decoration-line: underline;
    cursor: pointer;
}

label {
    font-size: small;
    color: var(--gray-500);
}


.logo {
    height: 100%;
    width: 100%;
    padding-block: 48px;
}

/*Table*/

table {
    margin-inline: auto;
    text-align: left;
    border-collapse: collapse;
}

table * {
    margin: 0;
    padding: 0;
}

td {
    border-top: var(--gray-300) 1px solid;
    padding-block: .75rem;
}

td, th {
    padding-block: .75rem;
}

:is(td, th):nth-child(1) {
    padding-right: 3rem;
}

/*Form*/

form input {
    flex: 1;
    font-size: 1rem;
    padding: 6px;
    background: var(--gray-50);
    border: 1px solid var(--gray-300);
    border-radius: .25rem 0 0 .25rem;
}

form :is(input:focus, button:focus) {
    outline: solid 1px var(--primary);
}

form button {
    color: var(--gray-100);
    background: var(--gray-800);
    padding-inline: 20px;
    border-radius: 0 .25rem .25rem 0;
    border: 0;
}

@keyframes spinning {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spin {
    animation: spinning 1s linear infinite;
}

.container {
    width: 100%;
    margin-inline: auto;
}

body {
    padding-inline: 50px;
}

@media ( min-width: 768px ) {
    .container {
        max-width: 768px;
    }

    p {
        font-size: 1.1rem;
    }

    h1 {
        font-size: 2.5rem;
    }
}